.ant-layout {
  background-color: #fff;
  height: 100%;
}
.ant-layout .header-container {
  min-height: 5.063rem;
}
.ant-layout .header-container label:hover {
  color: #8c8c8c;
}
.ant-layout .header-container label:hover span.anticon-copy {
  color: #096dd9;
  font-size: 1rem;
  pointer-events: all;
  cursor: pointer;
}
.ant-layout .header-container .anticon-copy {
  color: #00000000;
  font-size: 1rem;
  margin-left: 0.5rem;
  pointer-events: none;
  cursor: default;
}
.ant-layout .header-container .shared-case-icon {
  margin-left: 1rem;
}
.ant-layout .header-container .exit-button {
  margin-left: auto;
  margin-right: 1.077rem;
}
.ant-layout .header-container .exit-button.ant-tooltip-open {
  cursor: pointer;
}
.ant-layout .ant-layout-content .case-not-found .card-class-custom {
  width: 40.813rem;
  height: 12.125rem;
  margin: 10.625rem auto;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.16);
}
.ant-layout .ant-layout-content .case-not-found .card-class-custom .ant-card-head {
  border-bottom: 0;
  text-align: center;
  font-size: 2rem;
  font-weight: 500;
}
.ant-layout .ant-layout-content .case-not-found .card-class-custom .ant-card-body {
  text-align: center;
  font-size: 1.25rem;
  font-weight: 400;
  color: #595959;
}
.ant-layout .ant-layout-content .case-not-found .card-class-custom .ant-card-body p {
  display: inline-flex;
}
.ant-layout .ant-layout-content .case-not-found .card-class-custom .ant-card-body p span {
  align-self: center;
  font-size: 1.613rem;
  margin-right: 0.5rem;
}
.ant-layout .ant-layout-content .launch-logo {
  text-align: center;
  height: 9.37rem;
}
.ant-layout .ant-layout-content .launch-logo path {
  fill: #d9d9d9;
}
.ant-layout .ant-layout-content .casedetail-layout {
  padding: 1.5rem 1rem 0;
  background-color: #fff;
}
.ant-layout .ant-layout-content .casedetail-layout .card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.ant-layout .ant-layout-content .casedetail-layout .card-content .ant-tabs {
  margin-top: 1rem;
  overflow: unset;
  width: 100%;
}
.ant-layout .ant-layout-content .casedetail-layout .card-content .ant-tabs .ant-tabs-bar,
.ant-layout .ant-layout-content .casedetail-layout .card-content .ant-tabs .ant-tabs-top-bar {
  display: grid;
}
.ant-layout .ant-layout-content .casedetail-layout .card-content .ant-tabs .ant-tabs-tab {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  margin-right: 0.25rem;
  background-color: #dcdcdc;
}
.ant-layout .ant-layout-content .casedetail-layout .card-content .ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {
  color: #8c8c8c;
  font-size: 1rem;
}
.ant-layout .ant-layout-content .casedetail-layout .card-content .ant-tabs .ant-tabs-tab.ant-tabs-tab-active {
  background-color: #f5f5f5;
}
.ant-layout .ant-layout-content .casedetail-layout .card-content .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #0050b3;
  font-size: 1rem;
}
.ant-layout .ant-layout-content .casedetail-layout .card-content .ant-tabs .favorite-icon {
  position: relative;
  bottom: 0.063rem;
  padding-left: 0.25rem;
  font-size: 0.75rem;
  color: #8c8c8c;
}
.ant-layout .ant-layout-content .casedetail-layout .card-content .ant-tabs .favorite-icon-active {
  position: relative;
  bottom: 0.063rem;
  padding-left: 0.25rem;
  font-size: 0.75rem;
  color: #faad14;
}
.ant-layout .ant-layout-content .casedetail-layout .card-content .ant-tabs .plan-type {
  margin-top: 0;
}
.ant-layout .ant-layout-content .casedetail-layout .card-content .ant-tabs .plan-type .ant-tabs-tab {
  background-color: transparent;
}
.ant-layout .ant-layout-content .casedetail-layout .card-content .last-modified {
  position: absolute;
  bottom: -3.5rem;
  right: 0.5rem;
  text-align: right;
  font-style: italic;
  padding-bottom: 1rem;
}
.ant-layout .ant-layout-content .casedetail-layout .container {
  justify-items: stretch;
  align-items: stretch;
  display: grid;
  grid-column-gap: 2rem;
  padding: 0;
}
.ant-layout .ant-layout-content .casedetail-layout .container .info-card {
  box-shadow: 0 0.125rem 0.25rem #00000028;
  border: 0.0625rem solid #e8e8e8;
  border-radius: 2px;
  margin-bottom: 1.5rem;
}
.ant-layout .ant-layout-content .casedetail-layout .container .info-card.info-card-last {
  margin-bottom: 0;
}
.ant-layout .ant-layout-content .casedetail-layout .container .info-card .link-span {
  color: #000;
}
.ant-layout .ant-layout-content .casedetail-layout .container .card-class-custom .ant-card-head-wrapper {
  justify-content: space-between;
}
.ant-layout .ant-layout-content .casedetail-layout .container .info-card-expanded {
  height: 100%;
}
.ant-layout .ant-layout-content .casedetail-layout .container .ant-card-head {
  padding: 0 1rem;
}
.ant-layout .ant-layout-content .casedetail-layout .container .warning-disclaimer-container {
  display: flex;
  align-self: center;
  margin-left: 0.4rem;
}
.ant-layout .ant-layout-content .casedetail-layout .container .warning-disclaimer-container span.anticon {
  color: #faad14;
  font-size: 1rem;
}
.ant-layout .ant-layout-content .casedetail-layout .container .warning-disclaimer-container .warning-disclamer-label {
  margin-left: 0.4rem;
}
.ant-layout .ant-layout-content .casedetail-layout .container .ant-card-extra {
  margin-left: inherit;
}
.ant-layout .ant-layout-content .casedetail-layout .container .ant-tooltip {
  word-wrap: none;
  word-break: normal;
  max-width: 17rem;
}
.ant-layout .ant-layout-content .casedetail-layout .container .card-body-container {
  display: grid;
  grid-template-columns: auto auto;
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;
}
.ant-layout .ant-layout-content .casedetail-layout .container .card-body-container .base-info-container .base-info-title {
  font-weight: 800;
  color: #202020;
}
.ant-layout .ant-layout-content .casedetail-layout .container .card-body-container .base-info-container .base-info-data {
  color: #8c8c8c;
}
.ant-layout .ant-layout-content .casedetail-layout .container .planning-info {
  height: 100%;
}
.ant-layout .ant-layout-content .casedetail-layout .container .ant-tabs-tabpane .last-modified {
  color: #595959;
  position: initial;
  padding: 1rem 0.5rem 0 0;
}
.ant-layout .ant-layout-content .casedetail-layout .container .planning-infocard .ant-btn.ant-btn-primary {
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-layout .ant-layout-content .casedetail-layout .container .planning-infocard .launch-component {
  min-height: 22.5rem;
  height: calc(100vh - 17.5rem);
}
.ant-layout .ant-layout-content .casedetail-layout .container .ant-card-body {
  overflow: hidden;
  overflow-y: visible;
  height: 100%;
  padding: 1rem;
}
.ant-layout .ant-layout-content .casedetail-layout .container .ant-card-body .spinner {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-layout .ant-layout-content .casedetail-layout .container .ant-card-body .patient-empty {
  height: 11.375rem;
}
.ant-layout .ant-layout-content .casedetail-layout .container .ant-card-body .surgery-empty {
  height: 3.125rem;
}
.ant-layout .ant-layout-content .casedetail-layout .container .ant-card-body .notes-empty {
  height: 4.875rem;
}
.ant-layout .ant-layout-content .casedetail-layout .container .ant-card-body .planning-empty {
  height: calc(100vh - 7.5rem);
}
.ant-layout .ant-layout-content .casedetail-layout .container .ant-card-body .ant-checkbox-inner {
  background-color: transparent !important;
}
.ant-layout .ant-layout-content .casedetail-layout .container .ant-card-body .inputcell-label {
  color: #595959;
}
.ant-layout .ant-layout-content .casedetail-layout .container .ant-card-body .inputcell-value {
  margin-top: 0.5rem;
}
.ant-layout .ant-layout-content .casedetail-layout .container .ant-card-body .inputcell-value .input-container {
  height: 1.5rem;
  width: 100%;
  border-radius: 0.25rem;
}
.ant-layout .ant-layout-content .casedetail-layout .container .ant-card-body .user-email-input .anticon-copy {
  margin-left: 0.5rem;
  color: #0050b3;
}
.ant-layout .ant-layout-content .casedetail-layout .container .ant-card-body .user-email-input .inputcell-value .ant-input {
  text-decoration: underline;
}
.ant-layout .ant-layout-content .casedetail-layout .container .ant-card-body .ant-picker {
  width: 100%;
  border-radius: 0.25rem;
}
.ant-layout .ant-layout-content .casedetail-layout .container .ant-card-body .ant-picker-suffix {
  color: #d9d9d9;
}
.ant-layout .ant-layout-content .casedetail-layout .container .ant-card-body .ant-select-sm {
  width: 100%;
}
.ant-layout .ant-layout-content .casedetail-layout .container .ant-card-body .ant-select-sm > .ant-select-selection:hover {
  color: #b8b8b8 !important;
}
.ant-layout .ant-layout-content .casedetail-layout .container .ant-card-body .ctscan-layout {
  display: flex;
  justify-content: center;
}
.ant-layout .ant-layout-content .casedetail-layout .container .ant-card-body .ctscan-layout > .scan {
  width: 100%;
  position: relative;
}
.ant-layout .ant-layout-content .casedetail-layout .container .ant-card-body .ctscan-layout > .scan.axial-view {
  margin-right: 1rem;
}
.ant-layout .ant-layout-content .casedetail-layout .container .ant-card-body .ctscan-layout > .scan > img {
  width: 100%;
  height: 100%;
}
.ant-layout .ant-layout-content .casedetail-layout .container .ant-card-body .ctscan-layout .launch-logo {
  height: 6.5rem;
  padding-top: 0.75rem;
}
.ant-layout .ant-layout-content .casedetail-layout .container .ant-card-body .ctscan-layout .launch-recon-label {
  padding-bottom: 0;
}
.ant-layout .ant-layout-content .casedetail-layout .container .ant-card-body .ctscan-layout .launch-recon-label .scans-text {
  color: #000;
  font-size: 0.875rem;
}
.ant-layout .ant-layout-content .casedetail-layout .container .ant-card-body .ctscan-layout .anticon.anticon-fullscreen {
  position: absolute;
  right: 0.3rem;
  top: 0.3rem;
  cursor: pointer;
  font-size: 1rem;
  color: #fff;
  filter: drop-shadow(0 0 2px #000);
}
.ant-layout .ant-layout-content .casedetail-layout .container .ant-card-body .ctscan-layout .base-slider {
  left: 10%;
  right: 10%;
  bottom: 0.65rem;
  width: 80%;
}
.ant-layout .ant-layout-content .casedetail-layout .container .ant-card-head-title {
  width: 100%;
}
@media screen and (min-width: 70rem) {
  .ant-layout .container-columns {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
  }
}
@media screen and (max-width: 70rem) {
  .ant-layout .container-columns {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 2fr;
  }
}
@media screen and (min-width: 70rem) {
  .launch-component {
    height: calc(100vh - 7.5rem);
    grid-column-start: -2;
    grid-column-end: -1;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .launch-component .card-content {
    color: #595959;
  }
  .launch-component .card-content div .ant-btn {
    margin-top: 0.75rem;
  }
}
@media screen and (max-width: 87rem) {
  .last-modified {
    margin-top: 1rem;
  }
}
@media screen and (min-width: 87rem) {
  .last-modified {
    margin-top: auto;
  }
}
.ant-tabs-ink-bar,
.ant-tabs-ink-bar-animated {
  background-color: #0050b3;
}
.ant-tabs-nav-container-scrolling {
  padding-right: 1rem;
  padding-left: 1rem;
}
.ant-tabs-tab-prev.ant-tabs-tab-arrow-show,
.ant-tabs-tab-next.ant-tabs-tab-arrow-show {
  width: 0.75rem;
  height: 100%;
  opacity: 1;
  pointer-events: auto;
}
div.planning-result-container {
  width: 100%;
  padding-top: 1rem;
}
div.planning-result-container.no-padding {
  padding-top: 0;
}
div.planning-result-container > .planning-result-title {
  font-size: 0.875rem;
  color: #202020;
  font-weight: 600;
  padding-bottom: 0.75rem;
}
div.planning-result-container > .planning-result-title.has-disclaimer {
  padding-bottom: 0;
}
div.planning-result-container > .planning-result-disclaimer {
  color: #ff0000;
  padding-bottom: 0.75rem;
  font-size: 0.65rem;
}
div.planning-result-container > .planning-result {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
div.planning-result-container > .planning-result > .planning-result-detail.header-second-column {
  flex: 66.666666%;
}
div.planning-result-container > .planning-result > .planning-result-detail > .value {
  width: 7.8rem;
  font-size: 1.125rem;
  color: #202020;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
div.planning-result-container > .planning-result > .planning-result-detail > .value.header {
  max-width: unset;
}
div.planning-result-container > .planning-result > .planning-result-detail.basis-primary {
  flex-basis: 100%;
}
div.planning-result-container > .planning-result > .planning-result-detail.basis-primary .value {
  max-width: 11.5rem !important;
}
@media screen and (max-width: 85.375rem) {
  div.planning-result-container > .planning-result > .planning-result-detail.basis-primary {
    flex-basis: 66.6666%;
  }
}
div.planning-result-container > .planning-result > .planning-result-detail.basis-secondary {
  flex-basis: 66.6666%;
}
@media screen and (max-width: 85.375rem) {
  div.planning-result-container > .planning-result > .planning-result-detail.basis-secondary {
    flex-basis: 33.3333%;
  }
}
div.planning-result-container > .planning-result > .planning-result-detail.basis-secondary .value {
  max-width: 11.5rem !important;
}
div.planning-result-container > .planning-result > .planning-result-detail > .attribute {
  font-size: 0.8rem;
  color: #8c8c8c;
  width: 7.8rem;
  padding-bottom: 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
div.planning-result-container > .planning-result > .planning-result-detail > .attribute.has-disclaimer {
  padding-bottom: 0;
}
div.planning-result-container > .planning-result > .planning-result-detail > .attribute-title {
  padding-bottom: 0;
  white-space: nowrap;
  overflow: visible;
  bottom: 1.15rem;
  position: relative;
}
div.planning-result-container > .planning-result > .planning-result-detail > .title-fit {
  bottom: 1.35rem;
  position: relative;
}
div.planning-result-container > .planning-result > .planning-result-detail > .disclaimer {
  color: #ff0000;
  font-size: 0.65rem;
}
div.planning-result-container > .planning-result-separator {
  border: 0.5px solid #e8e8e8;
  width: 100%;
  margin: 0;
}
.request-support-label {
  font-size: 1rem;
  text-align: center;
  padding-bottom: 0.75rem;
  padding-top: 1.5rem;
  color: #000;
}
.request-support-label > div {
  display: flex;
}
.request-support-label > div > div {
  padding-left: 0.5rem;
}
.request-support-label > div > i {
  padding-top: 0.18rem;
}
.expandable {
  flex-grow: 1;
}
.anticon-exclamation-circle-warning {
  color: #faad14;
  padding-right: 0.5rem;
}
.anticon-close-circle {
  color: #f5222d;
}
.case-status-info .ant-alert {
  margin-bottom: 0.5rem;
  display: flex;
}
.case-status-info .ant-alert .ant-alert-message {
  color: #595959;
  margin-left: 1rem;
}
.case-status-info .ant-alert .ant-alert-message a {
  color: #595959;
  text-decoration: underline;
}
.case-status-info .ant-alert .ant-alert-icon {
  position: absolute;
  top: 0.75rem;
  left: 0.5rem;
}
.case-status-info .status-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.case-status-info.IN_PROCESS .status-title {
  color: #0050b3;
}
.case-status-info.IN_PROCESS .alert-component {
  background-color: #e6f7ff;
  border: 1px solid #0050b3;
}
.case-status-info.READY .status-title {
  color: #337313;
}
.case-status-info.READY .alert-component {
  background-color: #f6ffed;
  border: 1px solid #52c41a;
}
.case-status-info.IN_REVIEW .status-title {
  color: #e3bd09;
}
.case-status-info.IN_REVIEW .alert-component {
  background-color: #fcf6db;
  border: 1px solid #e3bd09;
}
.case-status-info.CONTACT_SUPPORT .status-title {
  color: #ff7a00;
}
.case-status-info.CONTACT_SUPPORT .alert-component {
  background-color: #fdeedf;
  border: 1px solid #ff7a00;
}
.case-status-info.SUPPORT_REQUESTED .status-title {
  color: #000;
}
.case-status-info.SUPPORT_REQUESTED .alert-component {
  background-color: #f6f9f3;
  border: 1px solid #585858;
}
.case-status-info.REJECTED .status-title {
  color: #f5222d;
}
.case-status-info.REJECTED .alert-component {
  background-color: #f9f8f8;
  border: 1px solid #f5222d;
}
.case-status-info.ARCHIVED .status-title {
  color: #0050b3;
}
.case-status-info.ARCHIVED .alert-component {
  background-color: #e6f7ff;
  border: 1px solid #0050b3;
}
.surgery-date-container {
  justify-content: space-between;
  display: flex;
}
.declined {
  color: #096dd9;
  border-color: #096dd9;
  font-weight: normal;
  text-transform: uppercase;
}
.planned {
  color: #52c41a;
  border-color: #52c41a;
  font-size: 0.8rem;
  border-width: 0.5px;
  margin-right: 0;
}
.unplanned {
  color: #f5222d;
  border-color: #f5222d;
  font-size: 0.8rem;
  border-width: 0.5px;
  margin-right: 0;
}
.planned.surgery-date,
.unplanned.surgery-date {
  font-size: 0.9rem;
  font-weight: bold;
}
.logo-container .powered-by-logo {
  width: 7.5rem;
  padding-bottom: 1rem;
  margin-left: calc(100% - 7.5rem);
}
@media screen and (max-width: 70rem) {
  .logo-container .powered-by-logo {
    position: relative;
    bottom: 4rem;
    left: 105%;
  }
}
span.special-attention {
  color: #ff0000 !important;
  margin-right: 0.1875rem;
}
div.special-attention {
  background-color: #fff !important;
}
span.special-attention-icon-active {
  color: #ff0000 !important;
}
span.special-attention-icon {
  color: #8c8c8c !important;
}
