.ant-message-success {
  display: flex;
}
.ant-message-success .case-number {
  color: #0050b3;
}
.ant-message-success .anticon-check-circle {
  padding-top: 0.05rem;
}
.ant-message-success .anticon-close {
  position: relative;
  right: -0.75rem;
  top: -0.01rem;
  font-size: 0.8rem;
  color: #585858;
}
.ant-message-success .anticon-copy {
  color: #0050b3;
}
.anticon-loading {
  color: #0050b3 !important;
}
