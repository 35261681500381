.header-container {
  display: flex;
  justify-content: left;
  background-color: #f5f5f5;
  height: 5.0625rem;
  align-items: center;
}
.header-container > label {
  font-weight: bold;
  font-size: 1.875rem;
  color: #202020;
  padding-left: 1.5rem;
  display: inline-flex;
  align-items: center;
}
.header-container > .push-right {
  flex-grow: 1;
}
.header-container > .push-left {
  flex-grow: 0;
}
