.landing {
  color: #000;
}
.landing > .landing-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 2rem 2rem;
  font-size: 2rem;
  font-weight: 700;
}
@media screen and (max-width: 78.75rem) {
  .landing > .landing-header {
    font-size: 1.5rem;
  }
}
.landing > .landing-header > img {
  margin-bottom: 2rem;
  width: 15.875rem;
}
.landing > .landing-login {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.landing > .landing-login > .login-button {
  display: flex;
  width: 18rem;
  height: 3.825rem;
  font-size: 2rem;
  font-weight: 700;
  margin-top: 3.313rem;
  align-items: center;
  justify-content: center;
}
.landing > .landing-login > .login-text {
  font-size: 1.25rem;
  margin: 2.688rem 0;
  text-align: center;
  display: flex;
}
.landing > .landing-login > .login-text > .signup-link > .ant-btn {
  padding: 0;
}
.landing > .landing-login > .login-text > .signup-link > .ant-btn > span {
  padding-left: 0.5rem;
  font-size: 1.25rem;
  text-decoration: underline;
}
.landing > .landing-description {
  background-color: #e6f7ff;
  display: flex;
  justify-content: center;
  text-align: justify;
  padding: 2.188rem 6.938rem;
}
@media screen and (max-width: 78.75rem) {
  .landing > .landing-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.188rem 1rem;
  }
}
.landing > .landing-description > .description-right-column {
  width: 39rem;
  margin-left: 5.688rem;
  margin-top: 4.5rem;
}
@media screen and (max-width: 78.75rem) {
  .landing > .landing-description > .description-right-column {
    margin: 2rem;
    width: 100%;
  }
}
.landing > .landing-description > .description-right-column > p {
  font-size: 1.25rem;
  font-weight: 600;
}
.landing > .landing-description > .description-right-column > .description-link {
  color: #000;
  display: flex;
  align-items: flex-end;
}
.landing > .landing-description > .description-right-column > .description-link > .anticon-play-circle {
  font-size: 1.8rem;
  color: #003a8c;
}
.landing > .landing-description > .description-right-column > .description-link > .video-link > .ant-btn {
  padding-left: 0.4rem;
}
.landing > .landing-description > .description-right-column > .description-link > .video-link > .ant-btn > span {
  color: #000;
  font-size: 1.25rem;
  text-decoration: underline;
}
.landing > .landing-description > img {
  width: 33.313rem;
  height: 100%;
}
@media screen and (max-width: 78.75rem) {
  .landing > .landing-description > img {
    width: 50%;
    height: 50%;
  }
}
.landing > .landing-subtitle {
  background-color: #f5f5f5;
  padding: 3rem 1rem 2rem;
  font-size: 2rem;
  text-align: center;
}
.landing > .landing-footer {
  background-image: url("../../resources/layout/landing-background.png");
  background-size: cover;
  background-color: #000;
  height: 50rem;
  color: #fff;
  font-size: 1.25rem;
}
@media screen and (max-width: 78.75rem) {
  .landing > .landing-footer {
    height: 100%;
  }
}
.landing > .landing-footer > .footer-description-container {
  padding: 12.688rem 0 0 5.188rem;
  width: 37.25rem;
}
@media screen and (max-width: 78.75rem) {
  .landing > .landing-footer > .footer-description-container {
    padding: 2rem;
    width: 100%;
    text-align: justify;
  }
}
.landing > .landing-footer > .footer-description-container > .footer-learn-more {
  padding-top: 1.438rem;
}
.landing > .landing-footer > .footer-description-container > .footer-learn-more > .genesis-link > .ant-btn {
  padding: 0;
}
.landing > .landing-footer > .footer-description-container > .footer-learn-more > .genesis-link > .ant-btn > span {
  text-decoration: underline;
  color: #fff;
  font-size: 1.25rem;
}
.landing > .landing-footer > .footer-description-container > .logos {
  padding-top: 1.438rem;
}
@media screen and (max-width: 78.75rem) {
  .landing > .landing-footer > .footer-description-container > .logos {
    text-align: center;
  }
}
.landing > .landing-footer > .footer-description-container > .logos .provoyance-logo {
  width: 13rem;
}
.landing > .landing-footer > .footer-description-container > .logos .si-logo {
  width: 8rem;
  margin-left: 3.563rem;
}
@media screen and (max-width: 78.75rem) {
  .landing > .landing-footer > .footer-description-container > .logos .si-logo {
    margin-left: 0;
  }
}
