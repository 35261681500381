.main-layout {
  display: flex;
  user-select: none;
  -webkit-user-select: none;
}
.main-layout .main-content {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100vh;
  background-color: #f5f5f5;
}
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem;
}
