.cases-list-container {
  background-color: #fff;
  height: 100%;
}
.cases-list {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1.5rem;
  background-color: #fff;
}
.ant-table {
  background: white;
  box-shadow: 0 0.125rem 0.25rem #00000028;
  color: rgba(0, 0, 0, 0.65);
}
.ant-table-content {
  border-radius: 0.25rem;
}
.ant-table-thead > tr > th {
  background: #e8e8e8;
  border-bottom: #e8e8e8;
}
.ant-table-thead > tr > th .ant-table-header-column {
  font-weight: 500;
}
.ant-table-tbody > tr > td {
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 10rem;
}
.case-number-column {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.case-number-column .anticon-copy {
  display: none;
  font-size: 1rem;
  margin-left: 0.5rem;
  color: #0050b3;
}
.case-number-column .anticon-copy:hover,
.case-number-column .anticon-copy:focus {
  color: #096dd9;
}
.case-number-column .shared-case-icon {
  margin-left: auto;
  height: 1.549rem;
}
.ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 0;
}
.ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: 0;
}
.row-link .link {
  color: #0050b3;
  padding: 0 0.5rem;
}
.row-link .link:hover,
.row-link .link:focus {
  color: #096dd9;
}
.select-search-dropdown {
  display: grid;
  max-width: fit-content;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.select-search-dropdown .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin: 0;
}
.select-search-dropdown .ant-checkbox-wrapper.input-container {
  white-space: nowrap;
  padding: 0.313rem 0.75rem;
}
.select-search-dropdown .ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
  background-color: #e6f7ff;
  color: #0050b3;
}
.select-search-dropdown .buttons-search {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.ant-checkbox-checked::after {
  border: 0;
}
.ant-checkbox-inner {
  border-radius: 0.25rem;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0050b3;
  border-color: #0050b3;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border-color: #0050b3;
}
.ant-table-filter-dropdown {
  border-radius: 0.25rem;
  color: #0050b3;
}
.ant-table-filter-dropdown .ant-input {
  height: 1.699rem;
  border-radius: 0.25rem;
}
.ant-table-filter-dropdown .ant-input:focus {
  border-color: #0050b3;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px #00000028;
  box-shadow: 0 0 0 2px #00000028;
}
.ant-table-filter-dropdown .buttons-search {
  display: flex;
  padding-top: 0.25rem;
}
.ant-table-filter-dropdown .buttons-search .button-primary {
  justify-content: center;
  flex-grow: 1;
}
.ant-table-filter-dropdown .buttons-search .button-default {
  justify-content: center;
  flex-grow: 2;
  margin-right: 0.5rem;
}
.ant-table-filter-dropdown .ant-picker.ant-picker-range {
  width: 25rem;
  height: 3rem;
  text-align-last: center;
}
.ant-table-filter-dropdown:hover {
  color: #1890ff;
}
.ant-table-filter-dropdown:active {
  color: #0050b3;
}
.ant-table-filter-dropdown-link {
  color: #0050b3;
}
.ant-table-filter-dropdown-link:hover {
  color: #096dd9;
}
.ant-table-filter-dropdown-btns {
  justify-content: flex-end;
}
.ant-table-filter-dropdown-btns > button {
  padding: 0 1rem;
}
.ant-table-filter-dropdown-btns > button.ant-btn-link {
  margin-right: 0.5rem;
  box-shadow: 0 0.125rem 0 #00000028;
  border-radius: 4px;
  background: #fff;
  border: 0.0625rem solid #e8e8e8;
  color: #0050b3;
}
.ant-table-filter-dropdown-btns > button.ant-btn-link:hover {
  border-color: #096dd9 !important;
  color: #096dd9;
}
.ant-table-filter-dropdown-btns > button.ant-btn-link:disabled {
  color: #8c8c8ca6;
}
.ant-table-filter-dropdown-btns > button.ant-btn-primary {
  background-color: #0050b3;
  box-shadow: 0 0.125rem 0 #00000028;
  border-color: #0050b3;
}
.ant-table-filter-dropdown-btns > button.ant-btn-primary:hover {
  background-color: #096dd9;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #e6f7ff;
}
.filtered-column {
  color: #0050b3 !important;
}
.ant-table-column-sorters .ant-table-column-sorter.ant-table-column-sorter-full .anticon.active {
  color: #030852;
}
.ant-table-thead > tr > th .ant-table-filter-trigger.active {
  color: #030852;
}
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a {
  color: #0050b3;
  background-color: #e6f7ff;
}
.ant-select-item.ant-select-item-option.ant-select-item-option-selected {
  background-color: #e6f7ff;
}
.ant-select-item.ant-select-item-option:hover {
  background-color: #e6f7ff;
}
.ant-select-focused.ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: #096dd9;
}
.ant-pagination {
  margin-top: 1rem;
  text-align: center;
}
.ant-pagination .ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination .ant-pagination-next:hover .ant-pagination-item-link,
.ant-pagination .ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination .ant-pagination-next:focus .ant-pagination-item-link {
  border-color: #096dd9 !important;
}
.ant-pagination ul.ant-pagination.ant-table-pagination {
  display: flex;
  justify-content: center;
  float: unset;
}
.ant-pagination ul.ant-pagination.ant-table-pagination li .ant-pagination-item-link {
  border-radius: 0.25rem !important;
}
.ant-pagination ul.ant-pagination.ant-table-pagination li .ant-select-arrow .anticon {
  transition: all 2 linear;
}
.ant-pagination ul.ant-pagination.ant-table-pagination li .ant-select-open .ant-select-arrow .anticon {
  transform: rotate(180deg);
}
.ant-pagination ul.ant-pagination.ant-table-pagination li .ant-select-selector {
  border-radius: 0.25rem !important;
  height: 2rem !important;
  padding-top: 0 !important;
}
.ant-pagination ul.ant-pagination.ant-table-pagination li .ant-select-selector .ant-select-selection-item {
  padding-right: 1rem;
  color: #252525;
}
.ant-pagination ul.ant-pagination.ant-table-pagination li .ant-select-selector:hover {
  border-color: #0050b3;
}
.ant-pagination ul.ant-pagination.ant-table-pagination li .ant-select-selector .ant-select-selection-item:active,
.ant-pagination ul.ant-pagination.ant-table-pagination li .ant-select-selector .ant-select-selection-item:hover,
.ant-pagination ul.ant-pagination.ant-table-pagination li .ant-select-selector .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-pagination ul.ant-pagination.ant-table-pagination li .ant-select-selector .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #096dd9;
  color: #096dd9;
}
.ant-pagination .ant-pagination-item-active {
  border-color: #096dd9;
}
.ant-pagination .ant-pagination-item-active a {
  color: #096dd9;
  font-weight: 600;
}
.ant-pagination .ant-pagination-item {
  border-radius: 0.25rem !important;
}
.ant-pagination .ant-pagination-item:hover,
.ant-pagination .ant-pagination-item:focus {
  border-color: #096dd9;
}
.ant-pagination .ant-pagination-item:hover a,
.ant-pagination .ant-pagination-item:focus a {
  color: #096dd9;
  font-weight: 600;
}
.ant-select-selection:hover {
  border-color: #096dd9;
  color: #096dd9;
}
.status-unplanned span {
  color: #f5222d;
  border-color: #f5222d;
  background-color: #00000000;
  font-weight: 100;
}
.status-planned span {
  color: #52c41a;
  border-color: #52c41a;
  background-color: #00000000;
  font-weight: 100;
}
.status-declined span {
  color: #0050b3;
  border-color: #0050b3;
  background-color: #00000000;
  font-weight: 100;
}
.action-link {
  text-align: center;
  display: none;
  align-items: center;
  max-width: 5rem;
}
.action-link span {
  color: #0050b3;
}
.action-link .anticon-eye {
  margin-top: 0.25rem;
  margin-right: 1rem;
  color: #0050b3;
}
.action-link .action-disabled {
  color: #8c8c8c;
}
.IN_PROCESS {
  color: #0050b3;
}
.READY {
  color: #337313;
}
.IN_REVIEW {
  color: #e3bd09;
}
.CONTACT_SUPPORT {
  color: #ff7a00;
}
.SUPPORT_REQUESTED {
  color: #000;
}
.REJECTED {
  color: #f5222d;
}
.ARCHIVED {
  color: #0050b3;
}
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background-color: #e6f7ff;
  cursor: pointer;
}
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td .action-link {
  display: flex;
}
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td .anticon-copy {
  display: flex;
}
.ant-table-column-title > .ant-btn {
  padding: 0;
}
.ant-table-scroll-horizontal {
  box-shadow: 0 2px 4px #00000028;
}
.ant-table-scroll-horizontal .ant-table-placeholder {
  height: 11rem;
}
.ant-table-scroll-horizontal .ant-table-placeholder .error-info {
  padding-top: 3.125rem;
}
.ant-table-scroll-horizontal .ant-table-placeholder .error-info > .error-info-text {
  padding-top: 0.325rem;
  color: #202020;
}
.ant-table-scroll-horizontal .ant-table-placeholder .error-info > span {
  font-size: 1.875rem;
}
.ant-table-scroll-horizontal .ant-table-placeholder .no-data {
  padding-top: 3.125rem;
}
.ant-table-scroll-horizontal .ant-table-placeholder .no-data > .no-data-text {
  padding-top: 0.325rem;
  color: #202020;
  font-weight: normal;
}
.ant-table-scroll-horizontal .ant-table-placeholder .no-data > svg {
  height: 2.75rem;
}
.ant-table-scroll-horizontal .ant-table-placeholder .no-data-yet {
  padding-top: 2rem;
}
.ant-table-scroll-horizontal .ant-table-placeholder .no-data-yet > .no-data-text {
  padding-top: 0.325rem;
  color: #202020;
  font-weight: normal;
}
.ant-table-scroll-horizontal .ant-table-placeholder .no-data-yet > span {
  font-size: 2.5rem;
}
.ant-table-layout-fixed table {
  table-layout: auto;
}
.loading-cases-icon {
  color: #0050b3;
  font-size: 1.25rem;
  margin-left: 1rem;
}
.quality-content {
  border: 1px solid #202020;
  border-radius: 4px;
  color: #202020;
  padding-right: 0.25rem;
  padding-left: 0.25rem;
  margin-right: 0.5rem;
}
.ct {
  color: #fff !important;
  border-color: #43a8c7 !important;
  background-color: #43a8c7 !important;
  width: 1.938rem;
  text-align: center;
}
.mr {
  color: #fff !important;
  border-color: #8f5bd6 !important;
  background-color: #8f5bd6 !important;
  width: 1.938rem;
  text-align: center;
}
.declined {
  color: #096dd9;
  border-color: #096dd9;
  background-color: #00000000;
  font-weight: 100;
}
