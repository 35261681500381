.ant-btn {
  display: flex;
  align-items: center;
}
.button-primary {
  box-shadow: 0 0.125rem 0 #00000028;
  border-radius: 4px;
  background-color: #0050b3;
  border-color: #0050b3;
  color: #fff;
}
.button-primary:hover,
.button-primary:focus {
  background-color: #096dd9;
  border-color: #096dd9;
}
.button-primary:disabled {
  color: #8c8c8c;
  box-shadow: 0 0.125rem 0 #00000028;
}
.button-primary:disabled:hover {
  color: #8c8c8c;
}
.button-default {
  box-shadow: 0 0.125rem 0 #00000028;
  border-radius: 4px;
  background: #fff;
  border: 0.0625rem solid #e8e8e8;
  color: #0050b3;
}
.button-default:hover {
  border-color: #096dd9 !important;
  color: #096dd9;
}
.button-default:focus {
  border: 0.0625rem solid #e8e8e8;
}
.button-default.button-circle {
  color: #8c8c8c;
}
.button-default.button-circle:hover {
  border-color: #096dd9 !important;
  color: #096dd9;
}
.button-default.button-circle:focus {
  border: 0.0625rem solid #e8e8e8;
}
.button-link {
  color: #0050b3;
}
.button-link:hover,
.button-link:focus {
  color: #096dd9;
}
