.share-case-modal {
  min-width: 29.688rem;
}
.share-case-modal .ant-modal-content .required-information {
  color: #ff0000;
  font-size: 0.75rem;
}
.share-case-modal .ant-modal-content .ant-modal-header {
  border-bottom: none;
  padding-top: 1.063rem;
  padding-left: 1.125rem;
  padding-bottom: 0;
}
.share-case-modal .ant-modal-content .ant-modal-body {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 1.125rem;
}
.share-case-modal .ant-modal-content .ant-modal-body .email-input-title {
  display: flex;
  align-items: center;
}
.share-case-modal .ant-modal-content .ant-modal-body .email-input-title .anticon-info-circle {
  margin-top: 0.531rem;
  color: #c4c4c4;
}
.share-case-modal .ant-modal-content .ant-modal-body .title {
  font-size: 0.875rem;
  font-weight: 400;
  color: #595959;
  margin: 0;
  margin-top: 1.063rem;
  margin-bottom: 0.438rem;
  padding-right: 0.2rem;
}
.share-case-modal .ant-modal-content .ant-modal-body .share-case-emails .ant-select-selector {
  height: auto !important;
  width: 20.5rem !important;
  align-content: center;
}
.share-case-modal .ant-modal-content .ant-modal-body .share-case-emails .ant-select-selector .ant-select-selection-overflow .ant-select-selection-item {
  height: 1.5rem;
}
.share-case-modal .ant-modal-content .ant-modal-body .share-case-emails .ant-select-selector .ant-select-selection-overflow .ant-select-selection-item > .ant-select-selection-item-content {
  padding-top: 0.7rem;
}
.share-case-modal .ant-modal-content .ant-modal-body .share-case-emails .ant-select-selector .ant-select-selection-overflow .ant-select-selection-item > .ant-select-selection-item-remove {
  padding-top: 0.35rem;
}
.share-case-modal .ant-modal-content .ant-modal-body .share-case-emails .ant-select-selector .ant-select-selection-overflow .ant-select-selection-search-input {
  height: 1.5rem;
}
.share-case-modal .ant-modal-content .ant-modal-body .inputcell-container .inputcell-value textarea {
  border-radius: 0.25rem;
  height: 7.313rem !important;
}
.share-case-modal .ant-modal-content .ant-modal-body .ant-alert {
  display: flex;
  margin-top: 1.438rem;
  height: 4.673rem;
  background-color: #e6f7ff;
  border-color: #0050b3;
}
.share-case-modal .ant-modal-content .ant-modal-body .ant-alert .ant-alert-message {
  color: #595959;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-left: 1.426rem;
}
.share-case-modal .ant-modal-content .ant-modal-body .ant-alert .ant-alert-icon {
  color: #0050b3;
  font-size: 1.136rem;
  position: absolute;
  top: 0.51rem;
  left: 0.688rem;
}
.share-case-modal .ant-modal-content .ant-modal-body .agree-info-checkbox {
  margin-top: 1.063rem;
}
.share-case-modal .ant-modal-content .ant-input:hover {
  border-color: #003a8c;
  box-shadow: 0;
}
.share-case-modal .ant-modal-content .ant-input:focus {
  border-color: #003a8c;
  box-shadow: 0 0 0 0.1rem rgba(0, 58, 140, 0.25);
}
.share-case-modal .ant-modal-content .ant-modal-footer {
  display: flex;
  justify-content: flex-end;
  border-top: none;
}
