.additional-material {
  display: flex;
  flex-direction: column;
  margin-top: 1.375rem;
}
.additional-material > label {
  font-size: 1.875rem;
  font-weight: bold;
  color: #202020;
  padding-bottom: 0.6875rem;
}
.additional-material > label.videos-title {
  font-size: 1.25rem;
}
.additional-material > a {
  margin-bottom: 1.375rem;
}
.additional-material > a .ant-btn:hover,
.additional-material > a .ant-btn:focus,
.additional-material > a .ant-btn:active,
.additional-material > a .ant-btn.active {
  color: #0050b3;
  border-color: #0050b3;
}
.additional-material > a.user-manual-link {
  margin-bottom: 0.6875rem;
}
@media screen and (max-width: 78.75rem) {
  .additional-material > .videos-wrapper {
    text-align: left;
    align-self: center;
  }
  .additional-material > a {
    display: flex;
    justify-content: center;
  }
}
