.main-content {
  background-color: #fff !important;
  user-select: text;
  -webkit-user-select: text;
}
.main-content .error-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 12rem);
  overflow: hidden;
}
@media screen and (max-width: 47rem) {
  .main-content .error-screen .ant-card {
    width: 90% !important;
  }
}
.main-content .error-screen .ant-card {
  width: 40rem;
  padding: 2rem 1rem;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.16);
}
.main-content .error-screen .ant-card .ant-card-head {
  border-bottom: 0 !important;
}
.main-content .error-screen .ant-card .ant-card-head-title {
  text-align: center;
  font-size: 1.75rem;
  padding-bottom: 0;
}
.main-content .error-screen .ant-card .card-body-container {
  text-align: center;
  font-size: 1.25rem;
}
.main-content .error-screen .ant-card .card-body-container .anticon-close-circle {
  margin-right: 0.3rem;
}
.main-content .landing {
  color: #585858;
  display: flex;
  flex-direction: column;
  height: 100%;
}
@media screen and (min-width: 70rem) {
  .main-content .landing {
    padding: 0 30%;
  }
  .main-content .landing > .landing-title {
    width: 43rem;
  }
  .main-content .landing > .shared-features > .shared-features-item {
    width: 50rem;
  }
}
@media screen and (max-width: 70rem) {
  .main-content .landing {
    padding: 0 10%;
  }
}
.main-content .landing a {
  color: #0050b3 !important;
  text-decoration: underline;
}
.main-content .landing > .landing-title {
  color: #0050b3;
  font-size: 1.3rem;
  font-weight: 600;
  padding-top: 2rem;
  max-width: 43rem;
}
.main-content .landing > .shared-features {
  padding-top: 1rem;
  font-size: 1.1rem;
}
.main-content .landing > .shared-features > .shared-features-item {
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 1.2rem 0;
}
.main-content .landing > .shared-features > .shared-features-item > .check-icon {
  color: #52c41a;
  padding-right: 0.5rem;
}
.main-content .spinner {
  color: #0050b3;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 6rem);
  overflow: hidden;
}
.main-content .spinner > .anticon-spin {
  font-size: 2rem;
}
