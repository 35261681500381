@media screen and (min-width: 78.75rem) {
  .downloads-footer {
    display: flex;
    justify-content: center;
    column-gap: 3.6875rem;
    padding-top: 3.188rem;
    padding-bottom: 4.188rem;
    background-color: #f5f5f5;
  }
  .downloads-footer img {
    margin-top: 1.875rem;
    width: 43.3125rem;
    height: 27.438rem;
  }
}
@media screen and (max-width: 78.75rem) {
  .downloads-footer {
    text-align: center;
    padding-top: 3.188rem;
    padding-bottom: 4.188rem;
    background-color: #f5f5f5;
  }
  .downloads-footer img {
    width: 100%;
    max-width: 43.3125rem;
  }
}
