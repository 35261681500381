.alert-component {
  padding: 0.55rem 0.9375rem;
}
.alert-component.ant-alert-error {
  background-color: #fff1f0;
  border-color: #ffa39e;
}
.alert-component > .ant-alert-icon {
  position: static;
  padding-right: 0.5rem;
}
.alert-component > .ant-alert-close-icon {
  top: 0.45rem;
  right: 0.3125rem;
}
.ant-alert-message {
  letter-spacing: -0.03rem;
}
.dicom-alert {
  position: absolute;
  bottom: -1rem;
  width: 48%;
  z-index: 9999;
  padding: 0.5rem 0.25rem 0.5rem 1.6rem;
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
}
.dicom-alert .ant-alert-message {
  color: #0050b3;
}
.dicom-alert .ant-alert-icon {
  position: absolute;
  color: #0050b3;
  top: 0.5rem;
  left: 0.5rem;
}
.mr-info-alert {
  border-color: #0050b3;
  padding-right: 0.5rem !important;
}
.mr-info-alert .ant-alert-icon {
  color: #0050b3;
  font-size: 1.125rem;
  padding-right: 0;
  margin-right: 0.5rem;
}
.fav-plan-alert {
  padding: 0.4rem 0.5rem;
  margin: 0 0 auto 0.5rem !important;
  border-color: #f5f5f5;
  background-color: #f5f5f5;
}
.fav-plan-alert .ant-alert-icon {
  color: #0050b3;
  font-size: 1.125rem;
  padding-right: 0.375rem;
}
.fav-plan-alert .ant-alert-message {
  color: #0050b3;
}
