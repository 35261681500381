.navigation-menu {
  min-height: 100vh;
  width: 2.5rem;
  background-color: #0050b3;
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5rem;
}
.navigation-menu > .layout-logo-container {
  text-align: center;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}
.navigation-menu > .layout-selected > .layout-icon {
  color: #ffffff7f;
}
.navigation-menu > .menu-separator {
  flex-grow: 1;
}
.navigation-menu > .layout-selected > .ant-menu-submenu-title > .layout-user-option > .anticon-user {
  color: #0050b3;
  font-size: 0.75rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-menu-inline-collapsed > .ant-menu-item {
  color: #ffffff7f;
  height: 2rem;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-menu-inline-collapsed > .ant-menu-item .anticon {
  font-size: 1.25rem;
  display: flex;
  align-items: center;
}
.ant-menu-inline-collapsed > .ant-menu-submenu {
  height: 2rem;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-menu-inline-collapsed > .ant-menu-submenu .anticon {
  font-size: 1.25rem;
}
.ant-menu-inline-collapsed > .ant-menu-submenu .ant-menu-submenu-title {
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-menu-inline-collapsed > .ant-menu-submenu .layout-user-option {
  background-color: #ffffff7f;
  border-radius: 26px;
  width: 1.25rem;
  height: 1.25rem;
  justify-content: center;
  display: flex;
  align-items: center;
}
.ant-menu-inline-collapsed > .ant-menu-submenu .layout-user-option.about-icon {
  background-color: #0050b3;
}
.ant-menu-inline-collapsed > .ant-menu-submenu .layout-user-option.about-icon .anticon-question-circle {
  color: #ffffff7f;
  font-size: 1.25rem !important;
}
.ant-menu-inline-collapsed .layout-selected.ant-menu-item-selected {
  background-color: #0003;
}
.ant-menu-inline-collapsed .layout-selected.ant-menu-item-selected .anticon {
  color: #fff;
}
.ant-menu-inline-collapsed .ant-menu-item-selected::before {
  background-color: #fff;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0.1875rem;
  height: 100%;
}
.ant-menu-inline-collapsed .ant-menu-submenu-open {
  background-color: #0003;
  position: relative;
}
.ant-menu-inline-collapsed .ant-menu-submenu-open .layout-user-option {
  background-color: #fff;
}
.ant-menu-inline-collapsed .ant-menu-submenu-open .layout-user-option.about-icon {
  background-color: #0003;
}
.ant-menu-inline-collapsed .ant-menu-submenu-open .layout-user-option.about-icon .anticon-question-circle {
  color: #fff;
}
.ant-menu-inline-collapsed .ant-menu-submenu-open::before {
  background-color: #fff;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0.1875rem;
  height: 100%;
}
.ant-menu-vertical.ant-menu-sub {
  background-color: #0050b3;
  color: #fff;
}
.ant-menu-item:hover,
.ant-menu-item-active {
  color: #fff;
  background-color: #0003;
}
.ant-menu-submenu-popup {
  left: 2.8rem !important;
  width: 12.5rem;
  height: 2rem;
  background-color: #00000000;
}
.ant-menu-vertical .ant-menu-item {
  margin-bottom: 0.25rem !important;
}
