.requirements-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0;
  width: 100%;
}
.requirements-container > .ant-card {
  width: 30rem !important;
}
.requirements-container > .ant-card > .ant-card-body {
  padding: 1rem;
}
@media screen and (max-width: 36.25rem) {
  .requirements-container > .ant-card {
    max-width: 90%;
  }
}
.requirements-container .specs-title {
  font-size: 0.9rem;
  color: #000;
}
.specs-columns {
  display: flex;
  justify-content: center;
}
.specs-columns > .ant-divider {
  height: auto;
}
.specs-columns > .spec-column {
  width: 100%;
  word-wrap: break-word;
}
.specs-columns > .spec-column p {
  margin-bottom: 0.25rem;
}
.specs-columns > .spec-column ul {
  margin: 0;
  padding: 0 0.45rem;
}
.specs-columns > .spec-column ul > li {
  list-style-type: disc;
}
