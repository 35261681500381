.portal-main-content {
  background-color: #fafafa !important;
  padding-bottom: 5rem;
}
.portal-main-content .welcome-label {
  display: flex;
  padding: 1.0625rem 0 1rem 2.75rem;
  font-size: 1rem;
  font-weight: bold;
  color: #202020;
}
@media screen and (min-width: 78.75rem) {
  .portal-main-content .download-content .download-children {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .portal-main-content .download-content .download-children > p {
    color: #202020;
    width: 58.938rem;
    height: 10.938rem;
    opacity: 1;
    padding-left: 0.1875rem;
  }
  .portal-main-content .download-content .download-children .provoyance-shoulder-logo {
    display: flex;
    justify-content: center;
    padding-bottom: 2rem;
    font-size: 1.8rem;
  }
  .portal-main-content .download-content .download-children .provoyance-shoulder-logo > img {
    width: 15.875rem;
  }
  .portal-main-content .download-content .download-children .download-buttons {
    display: flex;
    justify-content: center;
    margin: 0.625rem 0;
    flex-wrap: wrap;
    row-gap: 1rem;
  }
  .portal-main-content .download-content .download-children .download-buttons > a {
    margin: 0 1.5rem;
  }
  .portal-main-content .download-content .download-children .download-buttons > a .ant-btn.button-primary.button-undefined.ant-btn-primary {
    width: 11.175rem;
    justify-content: center;
  }
}
@media screen and (max-width: 78.75rem) {
  .portal-main-content .download-content {
    display: flex;
    flex-direction: column;
  }
  .portal-main-content .download-content .download-children {
    padding-bottom: 1rem;
  }
  .portal-main-content .download-content .download-children > p {
    color: #202020;
    width: 100%;
    opacity: 1;
    padding: 0 5% 4rem;
  }
  .portal-main-content .download-content .download-children .provoyance-shoulder-logo {
    display: flex;
    justify-content: center;
    padding-bottom: 2rem;
    font-size: 2rem;
  }
  .portal-main-content .download-content .download-children .provoyance-shoulder-logo > img {
    width: 15.875rem;
  }
  .portal-main-content .download-content .download-children .download-buttons {
    display: flex;
    justify-content: center;
    margin: 0.625rem 0;
    flex-wrap: wrap;
    row-gap: 1rem;
  }
  .portal-main-content .download-content .download-children .download-buttons > a {
    margin: 0 1.5rem;
  }
  .portal-main-content .download-content .download-children .download-buttons > a .ant-btn.button-primary.button-undefined.ant-btn-primary {
    width: 11.175rem;
    justify-content: center;
  }
}
@media screen and (max-width: 36.25rem) {
  .portal-main-content {
    padding-bottom: 0;
  }
}
