.latest-version {
  color: #337313;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.new-features-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fafafa !important;
  margin-bottom: 1rem;
}
.new-features-container .features-button {
  width: 30rem !important;
  height: 2.25rem;
}
@media screen and (max-width: 36.25rem) {
  .new-features-container .features-button {
    width: 90% !important;
  }
}
.new-features-container .features-button > .ant-space {
  overflow: hidden;
  justify-content: space-between;
  width: 100%;
}
.collapse-item {
  display: flex;
}
.collapse-item > .anticon-check-circle {
  font-size: 1.2rem;
  color: #52c41a !important;
  margin-top: 0.1rem;
}
.collapse-item > div {
  margin: 0 0 0 0.4rem;
  white-space: normal;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
  z-index: -999;
  width: inherit;
  margin-top: 0.3rem;
  border-radius: 0.25rem;
  opacity: 0;
  transform: translateY(-10px);
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.dropdown-menu > .ant-menu {
  width: auto;
  border-radius: 0.25rem;
  cursor: default !important;
  border: 0;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 16rem;
}
.dropdown-menu > .ant-menu > .ant-menu-item {
  margin: 0.75rem 0.75rem 0 0.75rem;
  padding: 0;
  background-color: #fff;
  height: fit-content;
  cursor: default !important;
}
.dropdown-menu > .ant-menu > .ant-menu-item > .collapse-item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.dropdown-menu > .ant-menu > .ant-menu-item > .collapse-item > span {
  margin: 0.25rem;
}
.dropdown-menu > .ant-menu > .ant-menu-item > .collapse-item > div {
  color: #000000a6 !important;
  line-height: 1.375rem;
  padding: 0.125rem;
}
.dropdown-menu > .ant-menu > .ant-menu-item:last-child {
  margin-bottom: 1rem !important;
}
.dropdown-menu.open {
  opacity: 1;
  z-index: 999;
  transform: translateY(0);
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.rotate-icon {
  transform: rotate(180deg);
  transition: all 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
