.dashboard {
  flex-direction: column;
  display: flex;
  align-items: initial !important;
}
.dashboard .dashboard-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  flex-direction: column;
}
.dashboard .dashboard-header .dashboard-name {
  font-weight: bold;
}
